<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
        <v-spacer />
        <v-icon @click="closeDialog">
          mdi-close
        </v-icon>
      </v-card-title>

      <ErrorAlert />

      <v-card-text class="pt-5 pb-0">
        <v-form
          ref="form"
          v-model="formValidity"
        >
          <v-text-field
            v-model="form.name"
            :rules="nameRules"
            :disabled="loading"
            :counter="30"
            outlined
            dense
            label="Name"
            @keypress.enter="saveItem"
          />
          <v-select
            v-model="form.driver"
            :rules="driverRules"
            :items="driverOptions"
            :disabled="loading"
            label="Driver"
            outlined
            dense
            @keypress.enter="saveItem"
          />
          <div v-if="form.driver">
            <v-divider />
            <div class="my-3">
              Credentials
            </div>
            <div
              v-if="loadingCredentials"
              class="d-flex justify-center align-center my-3"
            >
              <v-progress-circular
                width="2"
                size="20"
                indeterminate
                color="primary"
              />
              <span class="ml-3">Loading credentials...</span>
            </div>
            <div v-if="form.driver === 'lora_server' && !loadingCredentials">
              <v-text-field
                v-model="form.credentials.host"
                :disabled="loading"
                outlined
                dense
                label="Host"
                @keypress.enter="saveItem"
              />
              <v-text-field
                v-model="form.credentials.app_id"
                :disabled="loading"
                outlined
                dense
                label="App ID"
                @keypress.enter="saveItem"
              />
              <v-text-field
                v-model="form.credentials.access_key"
                :disabled="loading"
                outlined
                dense
                label="Access Key"
                @keypress.enter="saveItem"
              />
              <v-text-field
                v-model="form.credentials.username"
                :disabled="loading"
                outlined
                dense
                label="Username"
                @keypress.enter="saveItem"
              />
            </div>
            <div v-if="form.driver === 'ttn' && !loadingCredentials">
              <v-text-field
                v-model="form.credentials.region"
                :disabled="loading"
                outlined
                dense
                label="Region"
                @keypress.enter="saveItem"
              />
              <v-text-field
                v-model="form.credentials.app_id"
                :disabled="loading"
                outlined
                dense
                label="App ID"
                @keypress.enter="saveItem"
              />
              <v-text-field
                v-model="form.credentials.access_key"
                :disabled="loading"
                outlined
                dense
                label="Access Key"
                @keypress.enter="saveItem"
              />
              <v-text-field
                v-model="form.credentials.app_eui"
                :disabled="loading"
                outlined
                dense
                label="App EUI"
                @keypress.enter="saveItem"
              />
              <v-text-field
                v-model="form.credentials.process_id"
                :disabled="loading"
                outlined
                dense
                label="Process ID"
                @keypress.enter="saveItem"
              />
            </div>
            <div v-if="form.driver === 'chirpstack' && !loadingCredentials">
              <v-text-field
                v-model="form.credentials.host"
                :disabled="loading"
                outlined
                dense
                label="Host"
                @keypress.enter="saveItem"
              />
              <v-text-field
                v-model="form.credentials.app_id"
                :disabled="loading"
                outlined
                type="number"
                dense
                label="App ID"
                @keypress.enter="saveItem"
              />
              <v-text-field
                v-model="form.credentials.token"
                :disabled="loading"
                outlined
                dense
                label="Token"
                @keypress.enter="saveItem"
              />
            </div>
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          class="primary"
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormMixin from '../../mixins/FormMixin';

export default {
  name: 'IntegrationForm',
  mixins: [FormMixin],

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      form: {
        name: '',
        driver: null,
        credentials: {},
      },
      defaultForm: {
        name: '',
        driver: null,
        credentials: {},
      },
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 30 || 'Name must be less than 30 characters',
      ],
      driverRules: [
        (v) => !!v || 'Driver is required',
      ],
      driverOptions: [
        {
          text: 'The Things Network',
          value: 'ttn',
        },
        {
          text: 'Lora Server',
          value: 'lora_server',
        },
        {
          text: 'Chirpstack',
          value: 'chirpstack',
        },
      ],
      loadingCredentials: false,
    };
  },

  watch: {
    dialog(val) {
      if (val && this.isEdit) {
        if (this.form.driver === 'ttn') {
          this.form.credentials = {
            region: '',
            app_id: '',
            app_eui: '',
            process_id: '',
            access_key: '',
          };
        } else if (this.form.driver === 'lora_server') {
          this.form.credentials = {
            host: '',
            app_id: '',
            username: '',
            access_key: '',
          };
        } else if (this.form.driver === 'chirpstack') {
          this.form.credentials = {
            host: '',
            app_id: '',
            token: '',
          };
        }
        this.getDetail(this.form.id);
      }
    },
  },

  methods: {
    addData() {
      return this.$api.addIntegration(this.formData);
    },

    updateData() {
      return this.$api.updateIntegration({
        id: this.form.id,
        ...this.formData,
      });
    },

    async getDetail(id) {
      this.loadingCredentials = true;
      try {
        const result = await this.$api.getIntegrationDetail(id);
        this.form = result.data.data;
      } catch (error) {
        console.error(error);
        this.$toast.error('Failed Get Detail');
      }
      this.loadingCredentials = false;
    },
  },
};
</script>
