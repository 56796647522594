<template>
  <v-row>
    <v-col>
      <v-card>
        <v-toolbar flat>
          <v-icon>mdi-link-box</v-icon>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-toolbar-title class="d-none d-sm-flex align-center">
            <span>{{ camelToSentence($options.name) }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            color="primary"
            dark
            @click="openForm(null)"
          >
            Add
          </v-btn>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="meta.total"
          :loading="loading"
          disable-sort
          mobile-breakpoint="0"
          :footer-props="footerProps"
        >
          <!-- actions -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="mr-2"
                  v-on="on"
                  @click="openForm(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="error"
                  class="mr-2"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>

      <IntegrationForm
        ref="formModal"
        :item="item"
        @reload-data="getItem()"
      />
    </v-col>
  </v-row>
</template>

<script>
import DatalistMixin from '../../mixins/DatalistMixin';
import IntegrationForm from './IntegrationForm.vue';

export default {
  name: 'IntegrationList',
  components: { IntegrationForm },
  mixins: [DatalistMixin],
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          width: '200px',
        },
        {
          text: 'Driver',
          value: 'driver',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '120px',
        },
      ],
    };
  },


  methods: {
    getData(page = 1) {
      return this.$api.getIntegrations({
        page,
      });
    },

    deleteData(intId) {
      return this.$api.deleteIntegration(intId);
    },
  },
};
</script>
